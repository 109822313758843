import { post } from '@/utils/request';

// 收入列表
const incomeListReq = (data) => post({
  url: '/store/order.SundryCtl/incomeList',
  data: data,
});

// 款项列表
const getstreamTypeListReq = (data) => post({
  url: '/store/system.Stream/streamTypeList',
  data: data,
});

// 客户列表
const getUserListReq = (data) => post({
  url: '/store/common.Select/getAllUserlists',
  data: data,
});

// 获取员工列表（主辅销售)
const getEmployeeReq = () => post({
  url: '/store/authority.User/getEmployee',
});

// 支付方式
const getPayChannelCtlReq = (data) => post({
  url: '/store/hierarchy.PayChannelCtl/list',
  data: data,
});

// 新增
const createIncomeReq = (data) => post({
  url: '/store/order.SundryCtl/createIncome',
  data: data,
});

// 解锁
const getIsLockReq = (data) => post({
  url: '/store/shop.Settlement/getIsLock',
  data: data,
});

// 删除
const deleReq = (data) => post({
  url: '/store/order.SundryCtl/deleteIncomeBill',
  data: data,
});

// 详情
const getIncomeBillInfoReq = (data) => post({
  url: '/store/order.SundryCtl/getIncomeBillInfo',
  data: data,
});

// 更改状态
const updateStatusIncomeReq = (data) => post({
  url: '/store/order.SundryCtl/updateStatusIncome',
  data: data,
});

// 编辑
const updateIncomeReq = (data) => post({
  url: '/store/order.SundryCtl/updateIncome',
  data: data,
});

// 导出
const exportIncomeReq = (data) => post({
  responseType: 'blob',
  url: '/store/order.SundryCtl/exportIncome',
  data: data,
});

// 新增款项
const addStreamTypeReq = (data) => post({
  url: '/store/order.SundryCtl/addStreamType',
  data: data,
});

export {
  addStreamTypeReq,
  exportIncomeReq,
  updateIncomeReq,
  getIncomeBillInfoReq,
  deleReq,
  createIncomeReq,
  incomeListReq,
  getstreamTypeListReq,
  getUserListReq,
  getEmployeeReq,
  getPayChannelCtlReq,
  getIsLockReq,
  updateStatusIncomeReq,
}
