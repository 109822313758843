<template>
  <div>
    <!-- <div class="box"  v-if="row.stream_bill_type">
        <el-page-header @back="goBack" content="收支明细表">
        </el-page-header>
        {{row.item}}收支明细
    </div> -->
    <div class="card" :style="{ height: height }">
      <el-button
          type="primary"
          plain
          @click="handleExportAll"
          style="height: 40px"
          :loading="exportLoading"
          icon="el-icon-upload2"
          >导出</el-button
        >
        <el-button
          type="primary"
          style="height: 40px"
          @click="handlePrint"
          :loading="printAllLoading"
          >打印</el-button
        >
        <div class="line"></div>
        <div class="middle" id="middle">
          <el-form :model="form" :inline="true" class="form">
            <el-form-item label="门店">
                <el-select
                  v-model="form.hierarchy_id"
                  filterable
                  :disabled="userInfo.hierarchy_type_id === 40"
                  collapse-tags
                  clearable=""
                >
                  <template v-for="item in merchantList">
                    <el-option
                      :value="item.hierarchy_id"
                      :label="item.hierarchy_name"
                      :key="item.hierarchy_id"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="单据日期">
                <el-date-picker
                  v-model="form.create_time"
                  type="daterange"
                  align="right"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="款项类型">
                <el-select v-model="form.type" clearable="" filterable="">
                  <el-option label="收入" :value="10" ></el-option>
                  <el-option label="支出" :value="20" ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="单据编号">
                <el-input placeholder="请输入" v-model="form.order_number"></el-input>
              </el-form-item>
              <el-form-item label="单据类型">
                <el-select v-model="form.stream_bill_type" clearable="" filterable="">
                  <template v-for="item in streamType">
                    <el-option :label="item.name" :value="item.type" :key="item.type"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="客户">
                <el-select v-model="form.customer_id" clearable="" filterable="" placeholder="请输入姓名或电话" remote :remote-method="remoteMethod" :loading="selectLoading">
                  <template v-for="item in userList">
                    <el-option :label="item.realname + '-' + item.username" :value="item.client_user_id" :key="item.client_user_id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
          </el-form>
          <div style="margin: 0 10px 0 auto">
            <el-button
              @click="handleHeight"
              style="
                width: 40px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                padding: 0;
              "
            >
              <i class="el-icon-caret-bottom" v-if="height === '40px'"></i>
              <i class="el-icon-caret-top" v-else></i>
            </el-button>
          </div>
          <div style="display: flex">
            <el-button type="primary"  @click="handleSearch" style="height: 40px"
              >搜索</el-button
            >
            <el-button  @click="handleReset" style="height: 40px;">重置</el-button>
          </div>
        </div>
    </div>
    <div class="table">
        <el-table
          ref="table"
          border
          :data="tableData.list"
          stripe
          v-loading="loading"
          height="720"
          @row-dblclick="handleDalclick"
          show-summary=""
          :summary-method="getSummaries"
        >
          <template v-for="(item, index) in columnList">
            <el-table-column
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
            >
              <template slot-scope="scope">
                <template>
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column align="center" field_alias="operation" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="toDetail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
    </div>
    <div class='pagina'>
      <el-pagination
        background
        @size-change="handleSizeChange"
        layout='total, sizes, prev, pager, next, jumper'
        :total='tableData.total'
        :page-size='limit'
        :page-sizes="$store.state.pageSizes"
        @current-change='handleCurrentChange'
      ></el-pagination>
    </div>
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import {
  getMerchantListReq,
} from "@/api/goods/historyInv/index";
import LocalPrint from "@/components/localPrint/Index.vue";
import { getstreamTypeListReq, getUserListReq } from '@/api/statistics/finance/otherIncome';
import { getColumnsReq, getStreamListReq, getStreamTypeReq, exportSundryBillReq } from '@/api/statistics/finance/summary';
import storage from "good-storage";
import numeral from 'numeral';

export default {
  data() {
    return {
      selectLoading: false,
      row: {},
      streamType: [],
      localPrintVisible: false,
      title: '',
      printData: {},
      printRows: [],
      userList: [], // 客户列表
      streamTypeList: [],
      offsetHeight: "",
      height: "40px",
      loading: false,
      page: 1,
      limit: this.$store.state.pageSizes[0],
      tableData: {
        list: [],
        total: 0,
        sum: [],
      },
      columnList: [],
      userInfo: {},
      printAllLoading: false,
      exportLoading: false,
      form: {},
      merchantList: [], // 门店列表
    };
  },
  props: {
    // row: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
    outsideForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    if (this.$route.params.row) {
      this.row = this.$route.params.row;
      this.form = {
        ...this.form,
        create_time: this.$route.params.form.create_time,
        hierarchy_id: this.$route.params.form.hierarchy_id,
        stream_bill_type: this.$route.params.row.stream_bill_type,
      };
    }
    this.getMerchantList();
    this.getstreamTypeList();
    // this.getUserList();
    this.getStreamType();
    this.$nextTick(() => {
      // console.log(document.getElementById('middle').offsetHeight)
      // this.offsetHeight = document.getElementById('middle').offsetHeight + 'px';
    })
  },
  methods: {
    remoteMethod(key) {
      if (!key) {
        return;
      }
      this.getUserList(key);
    },
    // 跳转详情
    toDetail(row) {
      // 跳去销售单
      const name_keys = storage.get('name_keys');
      if (row.stream_source_type === 1) {
        if (name_keys['/order/offlineOrder/salesOrder']) {
          this.$router.push({
            name: 'offlineOrderSalesOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.data_id,
            },
          });
        } else {
          this.$router.push({
            name: 'offlineOrderMySalesOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.data_id,
            },
          });
        }
      }
      if (row.stream_source_type === 2) {
        if (name_keys['/order/offlineOrder/redeemOrder']) {
          this.$router.push({
            name: 'redeemOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.data_id,
            },
          });
        } else {
          this.$router.push({
            name: 'myRedeemOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.data_id,
            },
          });
        }
      }
      if (row.stream_source_type === 3) {
        if (name_keys['/order/offlineOrder/returnOrder']) {
          this.$router.push({
            name: 'offlineOrderReturnOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.data_id,
            },
          });
        } else {
          this.$router.push({
            name: 'offlineOrderMyReturnOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.data_id,
            },
          });
        }
      }
      if (row.stream_source_type === 4) {
        if (name_keys['/order/offlineOrder/exchangeOrder']) {
          this.$router.push({
            name: 'changeOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.data_id,
            },
          });
        } else {
          this.$router.push({
            name: 'myChangeOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.data_id,
            },
          });
        }
      }
      if (row.stream_source_type === 5) {
        // 收入
        this.$router.push({
          name: 'otherIncome',
          query: {
            t: Date.now(),
          },
          params: {
            key: row.sundry_bill_id,
          },
        });
      }
      if (row.stream_source_type === 6) {
        // 支出
          this.$router.push({
            name: 'otherOutcome',
            query: {
              t: Date.now(),
            },
            params: {
              key: row.sundry_bill_id,
            },
          });
      }
    },
    // 单据类型
    getStreamType() {
      getStreamTypeReq()
        .then((res) => {
          if (res.code === 1) {
            this.streamType = res.data;
          }
        });
    },
    getSummaries(param) {
        // const { columns, data } = param;
        // console.log(columns)
        // const sums = [];
        // columns.forEach((column, index) => {
        //   if (index === 0) {
        //     sums[index] = '合计';
        //     return;
        //   }
        //   const values = data.map(item => Number(item[column.property]));
        //   if (!values.every(value => isNaN(value)) && column.property !== 'customer_name') {
        //     sums[index] = values.reduce((prev, curr) => {
        //       const value = Number(curr);
        //       if (!isNaN(value)) {
        //         return prev + curr;
        //       } else {
        //         return prev;
        //       }
        //     }, 0);
        //     sums[index]  = numeral(sums[index]).format('0.00');
        //   } else {
        //     sums[index] = '';
        //   }
        // });

        // return sums;
        return this.tableData.sum;
      },
    // 获取数据
    getList() {
      this.loading = true;
      getStreamListReq({
        stream_bill_type: this.row.stream_bill_type,
        ...this.outsideForm,
        ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.columnList = res.data.head.map((item) => {
              return { ...item, width: item.t_width / 10 }
            });
            // res.data.sum.index = '合计';
            // res.data.list.push(res.data.sum);
            this.tableData = res.data;
            this.$nextTick(() => {
              this.$refs['table'].doLayout();
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取表头
    getColumns() {
      getColumnsReq({
        function_code: 'streamList',
      })
        .then((res) => {
          if (res.code === 1) {
            this.columnList = res.data.map((item) => {
              return { ...item, width: item.t_width / 10 }
            });
            this.$nextTick(() => {
              this.$refs['table'].doLayout();
              this.getList();
            });
          }
        });
    },
     // 获取客户列表
    getUserList(search) {
      this.selectLoading = true;
      getUserListReq({
        page: 1,
        limit: 99,
        search,
      })
        .then((res) => {
          this.selectLoading = false;
          if (res.code === 1) {
            this.userList = res.data.list;
          }
        })
        .catch(() => {
          this.selectLoading = false;
        });
    },
    // 获取款项列表
    getstreamTypeList() {
      // getstreamTypeListReq({
      //   type: 0,
      // })
      //   .then((res) => {
      //     if (res.code === 1) {
      //       this.streamTypeList = res.data;
      //     }
      //   })
    },
    handleHeight() {
       this.height = this.height === "40px" ? this.offsetHeight : "40px";
    },
    goBack() {
      this.$emit('onBack');
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
    handleDalclick(row) {
      this.toDetail(row);
    },
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset() {
      this.page = 1;
      this.form = {
        hierarchy_id: this.userInfo.hierarchy_type_id == 40 ? this.merchantList[0].hierarchy_id : '',
      };
      this.getList();
    },
    getMerchantList() {
      getMerchantListReq().then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
          if (!this.$route.params.row) {
            // console.log('设置门店')
            this.form = {
              ...this.form,
              hierarchy_id: this.userInfo.hierarchy_type_id == 40 ? res.data[0].hierarchy_id : '',
            };
          }
          // this.getColumns();
          this.getList();
        }
      });
    },
    handleExportAll() {
      this.exportLoading = true;
      exportSundryBillReq({
        export: 1,
        ...this.form,
        page: this.page,
        limit: this.limit,
        function_code: 'sundrybill',
      })
        .then((res) => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `收支明细表.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handlePrint() {
      if (!this.tableData.list.length) {
        this.$message.warning('没有可打印的数据');
        return;
      }
      this.title = '收支明细表';
      this.printRows = this.columnList;
      this.printData = this.tableData;
      this.localPrintVisible = true;
    },
  },
  components: {
    LocalPrint,
  },
}
</script>

<style lang="less" scoped>
.box {
  background: white;
  height: 48px;
  line-height: 48px;
  text-indent: 32px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #565656;
}
.card {
  display: flex;
  transition: all 0.3s;
}
.middle {
  display: flex;
  overflow: hidden;
}
</style>
