import { post } from '@/utils/request';

// 收入列表
const getColumnsReq = (data) => post({
  url: '/store/common.Column/index',
  data: data,
});

// 详情列表
const getStreamListReq = (data) => post({
  url: '/store/order.SundryCtl/streamList',
  data: data,
});

// 单据类型
const getStreamTypeReq = (data) => post({
  url: '/store/order.SundryCtl/getStreamType',
  data: data,
});

// 导出
const exportSundryBillReq = (data) => post({
  responseType: 'blob',
  url: '/store/order.SundryCtl/exportSundryBill',
  data: data,
});

// 收支汇总 - 表头
const getTotalColumnsReq = (data) => post({
  url: '/store/common.Column/index',
  data: data,
});

// 收支汇总
const getSunndrySummaryReq = (data) => post({
  url: '/store/order.SundryCtl/getSunndrySummary',
  data: data,
});

// 导出
const exportSummaryReq = (data) => post({
  responseType: 'blob',
  url: '/store/order.SundryCtl/exportSummary',
  data: data,
});

export {
  exportSummaryReq,
  getSunndrySummaryReq,
  getTotalColumnsReq,
  exportSundryBillReq,
  getColumnsReq,
  getStreamListReq,
  getStreamTypeReq,
}
